import React, { useState } from 'react';
import { format } from 'layout/util/moneyUtils';

const mapCartItems = (items) => {
  return items.map((item, index) => ({
    //  item_id: item.id,
    name: item.name,
    price: format(item.totalPrice.amount, item.totalPrice.currency),
    quantity: item.quantity,
    variant: item.defaultSku.productOptionsValue || '',
    url: item.productUrl,
    image:
      window.location.origin +
      (item.primaryMedia?.url || '/static/img/noPhoto.png'),
  }));
};

export const trackEvent = (eventName, order) => {
  var customerProperties = {
    email_id: order.customer.emailAddress,
    FIRSTNAME: order.customer.firstName,
    LASTNAME: order.customer.lastName,
  };

  var eventJson = {
    id: order.orderId,
    data: {
      total: order.total.amount,
      currency: order.total.currency,
      items: order.orderItems ? mapCartItems(order.orderItems) : [],
    },
  };
  sendinblue.track(eventName, customerProperties, eventJson);
};
